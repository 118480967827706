
.ant-btn-primary {
    color: #fff;
    background-color: #006ABF;
    border-color: #006ABF;
  }

.ant-btn-primary:hover {
    color: #fff;
    background-color: #004073;
    border-color: #004073;
}

/* Big Calendar CSS */

.rbc-calendar .rbc-event-label {
  font-size: 9px;
  /* transform: scale(0.75); */
}

.rbc-calendar .rbc-event-content {
  font-size: 11px;
  /* transform: scale(0.75); */
}

.rbc-calendar .rbc-event {
  padding: 0px 1px;
  display: block !important;
}  

.rbc-calendar .rbc-time-slot {
min-height: 30px;
}


/* Permission CSS Start*/

.p_admin_none .p_admin {
  display: none !important;
}

.p_company_none .p_company {
  display: none;
}
/* 
.p_news_view_none .p_news_view {
  display: none;
}

.p_dashboard_none .p_dashboard {
  display: none;
}

.p_dashboard_view_none .p_dashboard_view {
  display: none;
}

.p_company_none .p_company {
  display: none;
}

.p_company_view_none .p_company_view {
  display: none;
}

.p_setting_none .p_setting {
  display: none;
}

.p_setting_view_none .p_setting_view {
  display: none;
}

.p_staff_none .p_staff {
  display: none;
}

.p_staff_view_none .p_staff_view {
  display: none;
}

.p_staff_permission_none .p_staff_permission {
  display: none;
}

.p_staff_permission_view_none .p_staff_permission_view {
  display: none;
}

.p_staff_payroll_none .p_staff_payroll {
  display: none;
}

.p_staff_payroll_view_none .p_staff_payroll_view {
  display: none;
}

.p_staff_leave_none .p_staff_leave {
  display: none;
}

.p_staff_leave_view_none .p_staff_leave_view {
  display: none;
}

.p_reimbursement_none .p_reimbursement {
  display: none;
}

.p_reimbursement_view_none .p_reimbursement_view {
  display: none;
}

.p_store_none .p_store {
  display: none;
}

.p_store_view_none .p_store_view {
  display: none;
}

.p_store_roster_none .p_store_roster {
  display: none;
}

.p_store_roster_view_none .p_store_roster_view {
  display: none;
}

.p_report_none .p_report {
  display: none;
}

.p_report_view_none .p_report_view {
  display: none;
}

.p_letter_none .p_letter {
  display: none;
}

.p_letter_view_none .p_letter_view {
  display: none;
}

.p_tax_none .p_tax {
  display: none;
}

.p_tax_view_none .p_tax_view {
  display: none;
} */

/* Permission CSS End*/
